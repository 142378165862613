import Item from '@/classes/Item/Item.js'
import ItemProperty from '@/classes/Item/ItemProperty.js'
export default class Creative extends Item {
    constructor(params){
        super(params)
        this.unpack(params)
    }
    item_type = 'creative'
    user_defined_name = new ItemProperty({
        params: {
            name: 'user_defined_name',
            friendly_name: 'Name',
            html_input: {
                type: 'text',
                value: ''
            },
        },
        default_type: 'modifiable'
    })
    under_moderation = new ItemProperty({
        params: {
            name: 'under_moderation',
            friendly_name: 'Under Moderation',
        },
        default_type: 'displayed_not_modifiable'
    })
    variant = new ItemProperty({
        params: {
            name: 'variant',
            friendly_name: 'Variant',
        },
        default_type: 'not_displayed'
    })
    roblox_id = new ItemProperty({
        params: {
            name: 'roblox_id',
            friendly_name: 'Roblox ID',
            html_input: {
                type: 'text',
                value: ''
            },
        },
        default_type: 'modifiable'
    })
    img_type = new ItemProperty({
        params: {
            name: 'img_type',
            friendly_name: 'Image Type',
        },
        default_type: 'displayed_not_modifiable'
    })
    dimensions = new ItemProperty({
        params: {
            name: 'dimensions',
            friendly_name: 'Dimensions',
        },
        default_type: 'displayed_not_modifiable'
    })
    base_path = new ItemProperty({
        params: {
            name: 'base_path',
            friendly_name: 'Base Path',
        },
        default_type: 'not_displayed'
    })
    storage_path = new ItemProperty({
        params: {
            name: 'storage_path',
            friendly_name: 'Storage Path',
        },
        default_type: 'not_displayed'
    })
    img_url = new ItemProperty({
        params: {
            name: 'img_url',
            friendly_name: 'Image Url',
            user_modifiable: true,
        },
        default_type: 'not_displayed'
    })
    archived = new ItemProperty({
        params: {
            name: 'archived',
            friendly_name: 'Archived',
            user_modifiable: false,
        },
        default_type: 'not_displayed'
    })
    spritesheet_wait = new ItemProperty({
        params: {
            name: 'spritesheet_wait',
            friendly_name: 'Spritesheet Wait',
            user_modifiable: true,
            html_input: {
                type: 'number',
                value: '',
            },
        },
        default_type: 'modifiable'
    })
    use_spritesheet_method = new ItemProperty({
        params: {
            name: 'use_spritesheet_method',
            friendly_name: 'Use Spritesheet Method',
            user_modifiable: true,
            html_input: {
                type: 'checkbox',
                value: '',
            },
        },
        default_type: 'modifiable'
    })
    audio_id = new ItemProperty({
        params: {
            name: 'audio_id',
            friendly_name: 'Audio ID',
            user_modifiable: true,
            html_input: {
                type: 'text',
                value: '',
            },
        },
        default_type: 'modifiable'
    })
    relations = [
        {
            value: 'run', 
            friendly_name: 'Runs',
        },
        {
            value: 'campaign',
            friendly_name: 'Campaigns'
        }
    ]
}