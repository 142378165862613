<template>
    <div class="bg-white overflow-hidden shadow rounded-lg p-7">
        <form class="space-y-8 divide-y divide-gray-200" v-on:submit.prevent="uploadData">
            <div class="sm:col-span-6">

                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-3">Upload a Preview Gif *</h3>
                <UploadFile accept="image/gif"/>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Gif Name *</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="text" name="gif-name" id="gif-name" maxlength="50"
                            required autocomplete="off" v-model="user_defined_name" class="block w-full shadow-sm focus:ring-indigo-500
                            focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" /> 
                    </div>
                </div>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Sprite Sheet Frame Asset IDs Seperated By Commas *</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <textarea required v-model="sprite_csv" rows="4" id="sprite-frame-id" name="sprite-frame-id" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-1/2 block w-full sm:text-sm border-gray-300 rounded-md resize-none h-30" placeholder='rbxassetid://12345, "rbxassetid://6789"...' />
                    </div>
                </div>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Spritesheet Wait *</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="number" name="spritesheet_wait" id="spritesheet_wait"
                                required autocomplete="off" v-model="spritesheet_wait" min="0" step="0.01" class="block w-full shadow-sm focus:ring-indigo-500
                                focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" /> 
                    </div>
                </div>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Sprite Sheet End Value *</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="number" name="ss-end" id="ss-end" maxlength="50"
                                required autocomplete="off" v-model="spritesheet_end" min="1" max="4" class="block w-full shadow-sm focus:ring-indigo-500
                                focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" /> 
                    </div>
                </div>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter Audio Id</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="text" name="audio-id" id="audio-id" maxlength="50"
                                autocomplete="off" v-model="audio_id" class="block w-full shadow-sm focus:ring-indigo-500
                                focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" /> 
                    </div>
                </div>

                <button v-if="!loading" v-on:submit.prevent="uploadData" class="btn-primary indigo mr-auto mt-4">Submit</button>
                <LoadingComponent v-else />     
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            user_defined_name: '',
            upload_sprite_frames: false,
            sprite_csv: '',
            spritesheet_wait: 0.34,
            spritesheet_end: 4,
            audio_id: '',
        }
    },
    methods: {
        uploadData() {            
            if(confirm('Are you sure you want to upload this gif?')) {
                this.loading = true 
                let form_data = new FormData()

                if(this.upload_sprite_frames && this.sprite_csv.trim() == '') {
                    this.loading = false
                    return this.$notify({type: 'error', text: 'Sprite Frames Cant Be Empty!'})
                }

                form_data.append('image', this.stagedFile.file_object)
                form_data.append('user_defined_name', this.user_defined_name)
                form_data.append('upload_sprite_frames', this.upload_sprite_frames)
                form_data.append('sprite_csv', this.sprite_csv)
                form_data.append('spritesheet_wait', this.spritesheet_wait)
                form_data.append('spritesheet_end', this.spritesheet_end)
                form_data.append('audio_id', this.audio_id)

                const toasts = {'error': 'Error Uploading Gif Data', 'success': 'Gif Data Uploaded'}
                this.$http_request(this.$http.post, '/gifs', toasts, form_data, { headers: { 'Content-Type': 'multipart/form-data'} })
                    .then(() => {
                        this.loading = false
                        this.user_defined_name = ''
                        this.sprite_csv = ''
                        this.spritesheet_wait = 0.34
                        this.spritesheet_end = 4
                        this.audio_id = ''
                        this.$store.commit('file_upload_module/clearAllFiles')
                    }).catch( (error)=> {
                        this.$notify({type: 'error', text: error.response.data.message})
                        this.loading = false
                    })
            }
        },
    },
    computed: {
        creatives(){
            const creatives = this.$store.getters['getAllCreatives']
            if (!creatives) return []
            return creatives
        },
        existingFilenames() {
            const exisiting_filenames = []
            this.creatives.forEach((element) => {
                exisiting_filenames.push(element.user_defined_name+element.img_type)
            })
            return exisiting_filenames
        },
        stagedFile(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            return staged_files[0]
        },
    },
    created(){
        this.$store.commit('file_upload_module/clearAllFiles')
    }
}
</script>

