<template>
  <div>
    <!-- TEST COMMENT -->
      <div class="date-picker-wrapper">
        <div class="flex flex-column items-center mt-2">
            <label class="text-lg font-medium mr-4">Days In Interval:</label>
            <p class="align-top">{{ daysInSelectedInterval }}</p>
        </div>
        <VueDatePicker 
            class="p-2 m-1"
            v-model="userSelectionRange" 
            range 
            @update:model-value="dateChanged"
            :enable-time-picker="false"
            :preset-ranges="presetRanges"
            :clearable="false"
            :min-date="intervalMinMax.min" 
            :max-date="intervalMinMax.max" 
            prevent-min-max-navigation
        >
            <template #yearly="{ label, range, presetDateRange }">
                <span @click="presetDateRange(range)">{{ label }}</span>
            </template>
        </VueDatePicker>
    </div>

    <!-- Daily Average Play Time -->
    <div class="bg-white rounded-md p-2">
      <h1 class="text-center border-b-2 mb-2 p-4 text-lg font-bold">Portfolio Metrics </h1>
      <TableComponent
        :table_data="portfolioMetrics"
        :exported_file_name="'fortnite-portfolio-metrics-' + startDate + '_' + endDate"
        :total_column_heading_name="'Experience'"
        :disallowed_totals="['Join Code']"
        :disallowed_averages="['Join Code']"
        :default_sort_column="'Total Visits'"
      />
    </div>

  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import TableComponent from '@/components/TableComponent.vue'
import { differenceInCalendarDays, subDays } from 'date-fns';

export default {
    name: "FortnitePortfolioMetrics",
    components: {
        TableComponent,
        VueDatePicker,
    },
    data () {
        return {
            portfolioMetrics: [],
            intervalMinMax: {
                min: subDays(new Date(), 60),
                max: subDays(new Date(), 1),
            },
            dailyAveragePlayTime: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'Average Play Time': '00:00:00' }],
            dailyIslandRetention: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'Retention': 0.00 }],
            totalActivePlayers: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'Total Players': 0 }],
            newEcosystemPlayers: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'New Players': 0 }],
            returningEcosystemPlayers: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'Returning Players': 0 }],
            totalLifetimePlays: [{ 'Date': '01/01/1970', 'Join Code': '0000-0000-0000', 'Total Plays': 0 }],
            userSelectionRange: [subDays(new Date(), 30), subDays(new Date(), 1)],
        }
    },

    computed : {
        presetRanges() {
            const maxDate = this.intervalMinMax ? new Date(this.intervalMinMax.max) : new Date();
            const minDate = this.intervalMinMax ? new Date(this.intervalMinMax.min) : subDays(maxDate, 60);
            
            // Calculate the total available days in the interval
            const totalDays = differenceInCalendarDays(maxDate, minDate) + 1; // +1 to include both start and end dates
            
            // Determine sensible portions based on the total interval
            const intervals = [30, 60, 90].filter(days => days <= totalDays);
            
            // If the totalDays is less than the smallest interval, default to using the total interval
            if (intervals.length === 0) {
                intervals.push(totalDays);
            }

            // Generate the dynamic ranges
            const ranges = intervals.map(days => {
                const rangeStart = subDays(maxDate, days - 1); // -1 because we want to include the max date
                return {
                    label: `Prior ${days} Days From Most Recent`,
                    range: [rangeStart, maxDate],
                };
            });

            // Always include the total interval as the last option
            ranges.push({
                label: `Total Interval (${totalDays} Days)`,
                range: [minDate, maxDate],
            });

            return ranges;
        },
        daysInSelectedInterval() {
            const [startDate, endDate] = this.userSelectionRange;

            // Calculate the difference in time (in milliseconds)
            const differenceInTime = endDate - startDate;

            // Convert the time difference to days and add 1 to include both start and end dates
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)) + 1;

            return differenceInDays;
        },
        startDate() {
          if (!this.userSelectionRange.length) return '';
          const start = new Date(this.userSelectionRange[0]);
          return start.toISOString().split('T')[0];
        },
        endDate() {
          if (this.userSelectionRange.length < 2) return '';
          const end = new Date(this.userSelectionRange[1]);
          return end.toISOString().split('T')[0];
        },
    },
    async created() {
        await this.fetchMinMaxInterval();
        await this.fetchData();
    },
    methods: {
        dateChanged() {
            this.fetchData();
        },

        async fetchMinMaxInterval() {
            try {
                const response = await this.http_request(
                    this.$http.get, // Assuming `this.$http.get` is your HTTP GET method
                    '/fortnite/min-max-interval',
                    {
                        success: 'Successfully fetched interval data',
                        error: 'Failed to fetch interval data'
                    }
                );

                this.intervalMinMax = response.data; // Assign the fetched data to intervalMinMax

                if (response.data.min && response.data.max) {
                    const minDate = new Date(response.data.min);
                    const maxDate = new Date(response.data.max);
                    const totalDays = differenceInCalendarDays(maxDate, minDate) + 1; // +1 to include both min and max dates

                    if (totalDays <= 60) {
                        // If the interval is shorter than or equal to 60 days, use the full interval
                        this.userSelectionRange = [minDate, maxDate];
                    } else {
                        // If the interval is longer than 60 days, use the last 60 days from the max date
                        const startDate = subDays(maxDate, 59); // 59 days back to make it a 60-day range including max date
                        this.userSelectionRange = [startDate, maxDate];
                    }
                }

            } catch (error) {
                console.error('Error fetching interval data:', error);
            }
        },

        // { url: `/fortnite/daily-average-play-time?start=${startDate}&end=${endDate}`, key: 'dailyAveragePlayTime' },
        // { url: `/fortnite/daily-island-retention?start=${startDate}&end=${endDate}`, key: 'dailyIslandRetention' },
        // { url: `/fortnite/total-active-players?start=${startDate}&end=${endDate}`, key: 'totalActivePlayers' },
        // { url: `/fortnite/new-ecosystem-players?start=${startDate}&end=${endDate}`, key: 'newEcosystemPlayers' },
        // { url: `/fortnite/returning-ecosystem-players?start=${startDate}&end=${endDate}`, key: 'returningEcosystemPlayers' },
        // { url: `/fortnite/total-lifetime-plays?start=${startDate}&end=${endDate}`, key: 'totalLifetimePlays' },

        async fetchData() {
            const { startDate, endDate } = this;
            console.log('Fetching data for:', startDate, endDate);
            const endpoints = [
                { url: `/fortnite/portfolio-metrics-table?start=${startDate}&end=${endDate}`, key: 'portfolioMetrics' },
            ];

            for (const endpoint of endpoints) {
                try {
                    const response = await this.$http_request(
                        this.$http.get,
                        endpoint.url,
                        { success: undefined, error: `${endpoint.key} fetch failed` }
                    );
                    this[endpoint.key] = response.data.data;

                    console.log(`Fetched ${endpoint.key}:`, response.data.data);
                } catch (error) {
                    console.error(`Error fetching ${endpoint.key}:`, error);
                }
            }
        },
        async http_request(http_method, url, toasts={'success': undefined, 'error': undefined, 'confirm': undefined}, data=undefined, header=undefined, timeout = null) {
            if (toasts.confirm && confirm(toasts.confirm) || !toasts.confirm) {
                const config = {
                    headers: header,
                    timeout: timeout ? timeout * 1000 : 0 // Convert to milliseconds
                };

                this.$notify({id: 'loader', type: "loader", duration: 10000000, text: "Loading"});
                return http_method(url, data, config)
                    .then((response) => {
                        this.$notify.close('loader');
                        if (toasts.success) this.$notify({type: 'success', text: toasts.success});
                        return Promise.resolve(response);
                    })
                    .catch((error) => {
                        this.$notify.close('loader');
                        if (error.response && error.response.status === 413) this.$notify({type: 'error', text: 'Payload Is Too Large!'});
                        if (error.code === 'ECONNABORTED') this.$notify({type: 'error', text: 'The request timed out!'});
                        else if (toasts.error) this.$notify({type: 'error', text: toasts.error});
                        return Promise.reject(error);
                    });
            } else {
                this.$notify.close('loader');
                return Promise.reject();
            }
        }
    }
}
</script>




<style lang="postcss" scoped>
.date-picker-wrapper {
    display: inline-block;   /* Makes the div to fit its content */
}

.date-picker-wrapper .vue-daterange-picker {
    width: auto;  /* Adjust the width of date picker */
}

:deep .dp__action_select {
    color: white; 
    background-color: green; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep .dp__action_cancel {
    color: white; 
    background-color: red; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}
</style>